@import "../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400&display=swap");
.Banner {
  text-align: center;
  color: $links;
  // size: 100%;
  height: 100vh;
  // background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.03)), url('../../public/the_starry_night-wallpaper.jpg');
  background-image: url("../../../public/starryNight-min.jpeg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

#name {
  font-family: $font-name, $font-type;
  font-variation-settings: "wght" 400, "GRAD" 10;
  font-size: 110px;
  margin: 8vh 0 0 0;
  text-shadow: 2px 2px 10px #000000;
}

h2 {
  font-family: $font-name, $font-type;
  font-variation-settings: "wght" 300, "GRAD" 10;
  font-size: 80px;
  margin: 8vh 0 0 0;
  text-shadow: 2px 2px 10px #000000;
}

#profile {
  margin: 18vh 0px 0px 0px;
  border: 5px solid $links;
  border-radius: 50%;
  height: 24%;
  background: blur(5px);
  z-index: 1;
  backdrop-filter: blur(3px);
}

@media only screen and (max-width: 700px) {
  #name {
    font-size: 70px;
    margin-top: 4vh;
  }
  #profile {
    margin-top: 10vh;
  }
  h2 {
    font-size: 50px;
  }
}
