.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #FCDAB7;
}

.App-link {
    color: #61dafb;
}

html {
    /* overflow: hidden; */
    height: 100%;
}

body {
    height: 100%;
    overflow: auto;
}