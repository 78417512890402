@import "../variables.scss";
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #041c32;
  opacity: 0.999;
  overflow: hidden;
  box-shadow: 0 0 5px #0000000d, 2px 2px 10px $text;
  z-index: 1;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.empty {
  height: 47px;
}

.after {
  backdrop-filter: blur(50px);
}

.navLink {
  color: $links;
  float: left;
  display: block;
  text-align: center;
  padding: 14px 20px 14px 20px;
  text-decoration: none;
  margin: 0px;
}

html {
  scroll-behavior: smooth;
}

#connect {
  position: absolute;
  right: 0px;
}

@media only screen and (max-width: 700px) {
  .navLink {
    padding: $section-padding;
    font-size: 16px;
  }
}
