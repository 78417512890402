@import "../variables.scss";
h1 {
  font-family: $font-name, $font-type;
  font-size: 110px;
  margin: 67 0 0 0;
  font-weight: 200px;
  color: $links;
}

.section {
  min-height: 100vh;
  background-color: $background;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background-color: $section; /* background color */
  width: 60vw; /* width */
  margin: 100px 0 100px 0; /* top and bottom margin */
  height: auto; /* height */
  position: relative; /* position */
  border-radius: 10px; /* border radius */
  padding: $section-padding; /* padding */
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.5); /* box shadow */
  border: 2px solid $background; /* border */
  background: linear-gradient(45deg, $section, #f2f2f2);
}
.title {
  color: $text;
  margin: 0;
  padding: 10px;
  font-size: 50px;
  margin: auto;
}

.skill img {
  height: 65px;
  padding: 15px;
}

h3 {
  font-family: $font-name, $font-type;
  font-weight: 200px;
  color: $text;
  font-size: 30px;
  margin: 10px;
}

figure {
  display: inline-block;
  margin: $section-padding 10px $section-padding 10px;
}

figcaption {
  color: $text;
}

@media only screen and (max-width: 700px) {
  .container {
    width: 90vw;
    margin: 30px;
  }
  .skill img {
    height: 45px;
  }
}
